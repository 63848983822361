:root {
    --haber-bg-color: #0b5658;
    --haber-yellow-color: #f6ed5c;
    --haber-text-color: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--haber-bg-color);
    color: var(--haber-text-color);
}

#savings {
    background-color: var(--haber-yellow-color);
    color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
